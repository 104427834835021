<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>

    <v-card elevation="2" outlined shaped tile>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-text class="text-body-1 px-8 py-4">
        <v-row class="py-4">
          <v-btn color="primary" @click="goto_previous_page">
            BACK
          </v-btn>
        </v-row>
        <v-row class="mb-8 text-subtitle-1">
          <v-col cols="12" sm="3" md="3" lg="3" class="d-flex align-center">
            <h6 class="font-weight-bold">
              {{ routePos?.brand?.name }} / {{ routePos?.surveys?.[0]?.name }}
            </h6>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" class="text-center text-body-1">
            <div class="text-wrap">
              <strong>{{ routePos?.po?.chain?.name }}</strong> -
              {{ routePos?.po?.id }} - {{ routePos?.brandId }} -
              <span class="font-italic">{{ routePos?.po?.name }}</span> -
              {{ routePos?.po?.town }} - {{ routePos?.po?.postalCode }} -
              {{ routePos?.po?.address }}
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            class="d-flex align-center justify-end"
          >
            <sessionTagLabels :postaglabels="routePos?.po?.postaglabels" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="text-h6"
            >KPI's Punto de venta</v-col
          >
        </v-row>
        <v-divider
          style="background-color: #156082 !important; border-color: #156082 !important;"
          :thickness="6"
          class="border-opacity-100 primary"
        ></v-divider>
        <v-row class="mb-10">
          <v-col cols="12" sm="12" md="12" lg="12">
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">
                        Perfect Store
                      </th>
                      <th
                        v-for="index in kpi_length"
                        :key="index"
                        class="text-left"
                      >
                        {{ kpis[index - 1] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(surveyResponses,
                      index) in worksessionSurveyResponses"
                      :key="index"
                    >
                      <td v-if="index == 0">Ultima Visita</td>
                      <td v-if="index != 0">
                        V.Anterior {{ index > 1 ? `-${index - 1}` : "" }}
                      </td>
                      <td>
                        {{ surveyResponses?.[0]?.lastPSPoints || "0" }}
                      </td>
                      <td v-for="index in kpi_length" :key="index">
                        {{
                          index > surveyResponses.length
                            ? "0"
                            : surveyResponses?.[index - 1]?.[
                                "kpiObjectiveValue"
                              ] || "0"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="text-h6"
            >Fotos Punto de venta</v-col
          >
        </v-row>
        <v-divider
          style="background-color: #156082 !important; border-color: #156082 !important;"
          :thickness="10"
          class="border-opacity-100 primary"
        ></v-divider>
        <v-row>
          <template v-if="foundPortals.length > 0">
            <v-row class="portal-card-slider">
              <template v-for="(portalItem, key) in foundPortals">
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  class="d-flex justify-content-center my-4"
                  :key="`portal_item_${key}`"
                  v-if="
                    key >= visibleItems && key < visibleItems + itemsPerPage
                  "
                >
                  <v-card elevation="2" class="portal-card-slider-item">
                    <v-card-text>
                      <v-row>
                        <div class="mx-auto">
                          <v-btn
                            class="my-1 btn-portal-move-pos"
                            variant="outlined"
                            color="primary"
                            icon
                            :disabled="portalItemIndex[key] <= 0"
                            @click="onClickMovePos('prev', key)"
                          >
                            <b-icon
                              icon="caret-up-fill"
                              font-scale="3"
                            ></b-icon>
                          </v-btn>
                        </div>
                        <h6 class="text-center w-full">
                          {{
                            portalItem?.[portalItemIndex[key]]?.survey_question
                              ?.name
                          }}
                        </h6>
                        <v-col
                          cols="12"
                          md="12"
                          lg="12"
                          style="min-height: 100px"
                        >
                          <ImageSlider
                            :attachments="
                              getAttachments(portalItem?.[portalItemIndex[key]])
                            "
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <h5 class="text--primary">
                            {{
                              getPunctuationLabel(
                                portalItem?.[portalItemIndex[key]]
                              )
                            }}
                          </h5>
                          <v-col
                            cols="12"
                            sm="12"
                            class="text--primary"
                            v-if="
                              commentsPhotosPortal(
                                portalItem?.[portalItemIndex[key]]
                              ).length > 0
                            "
                          >
                            <v-col
                              cols="12"
                              md="12"
                              class="p-0"
                              :key="`photo_comment_${index}`"
                              v-for="(commentItem,
                              index) in commentsPhotosPortal(
                                portalItem?.[portalItemIndex[key]]
                              )"
                            >
                              <v-col
                                cols="12"
                                md="12"
                                lg="12"
                                class="p-0 d-flex align-center space-around"
                              >
                                <h6 class="m-0">
                                  {{ commentItem.label }}
                                </h6>
                                <v-rating
                                  dense
                                  length="5"
                                  size="23"
                                  v-model="commentItem.rating"
                                  :readonly="!commentItem.isAccess"
                                  :required="commentItem.isAccess"
                                  :rules="
                                    commentItem.isAccess ? requiredRules : []
                                  "
                                ></v-rating>
                              </v-col>
                              <v-col cols="12" md="12" lg="12" class="p-0">
                                <v-textarea
                                  :readonly="!commentItem.isAccess"
                                  v-bind:class="
                                    'gpv_comment_text ' +
                                      (!commentItem.isAccess ? 'readonly' : '')
                                  "
                                  :required="commentItem.isAccess"
                                  :rules="
                                    commentItem.isAccess
                                      ? requiredCommentsRules
                                      : []
                                  "
                                  v-model="commentItem.text"
                                  rows="2"
                                ></v-textarea>
                              </v-col>
                            </v-col>
                          </v-col>
                        </v-col>
                        <div class="mx-auto">
                          <v-btn
                            class="btn-portal-move-pos"
                            variant="outlined"
                            color="primary"
                            icon
                            :disabled="
                              portalItemIndex[key] >= portalItem?.length - 1
                            "
                            @click="onClickMovePos('next', key)"
                          >
                            <b-icon
                              icon="caret-down-fill"
                              font-scale="3"
                            ></b-icon>
                          </v-btn>
                        </div>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </template>
          <v-col v-else>No data to display</v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";
import sessionTagLabels from "@/components/sessionTagLabels";
import ImageSlider from "./ImageSlider.vue";

export default {
  name: "PosFotosDetail",
  components: { sessionTagLabels, ImageSlider },
  data: function() {
    return {
      total: 0,
      salesEmail: [],

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["id", "date", "email", "status"],
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          if (rowIndex % 2 !== 0) {
            className = "table-body-cell-even";
          } else {
            className = "table-body-cell-odd";
          }
          return className;
        }
      },
      filterlist_fetch_url:
        "reporting/sales/salespedidoemail/salesemail/getfilterlist",
      filterCriteria: {
        id: {},
        date: {},
        email: {},
        status: {}
      },
      portalItemIndex: {},
      showEmpty: false,

      loading: true,
      options: {},
      selected: null,
      showMessageDialog: false,
      valid: true,
      isSaving: false,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********************************** Loading Spinner Overlay **********************************/
      isLoadingSpinner: false,

      routePosId: null,
      surveyId: null,
      brandId: null,
      posId: null,
      routePos: null,
      worksessionSurveyResponses: [],
      foundPortals: [],
      kpi_length: 0,
      kpis: [],
      visibleItems: 0,
      initItemsPerPage: 20,

      requiredCommentsRules: [v => !!v || "Escribe los comentarios"]
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager", "user"]),
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 30,
          sortBy: "ASC",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["from"]}
                    value={this.filterCriteria["id"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["to"]}
                    value={this.filterCriteria["id"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "id")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "date",
          title: "Fecha",
          field: "date",
          align: "left",
          width: 65,
          sortBy: "ASC",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["from"]}
                    value={this.filterCriteria["date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["to"]}
                    value={this.filterCriteria["date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "email",
          title: "Correo electrónico",
          field: "email",
          align: "left",
          width: 80,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["email"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`email`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["email"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "email")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 45,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return this.getStatus(row.status);
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["pending"]}
                    label="Pendiente"
                    value="PENDING"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["completed"]}
                    label="Terminado"
                    value="COMPLETED"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["error"]}
                    label="Error"
                    value="ERROR"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["timeout"]}
                    label="Se acabó el tiempo"
                    value="TIMEOUT"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "error",
          title: "Error",
          field: "error",
          align: "left",
          width: 80
        },
        {
          key: "q",
          title: "Ir",
          field: "actions",
          align: "center",
          width: 35,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemShow(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
      return [...common_cols];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    itemsPerPage() {
      return this.initItemsPerPage;
    }
  },
  methods: {
    moment,
    goto_previous_page() {
      this.$router.back();
    },
    onClickMovePos(type, key) {
      console.log("---------------------", type, key);
      if (type === "prev") {
        this.portalItemIndex[key] -= 1;
      } else {
        this.portalItemIndex[key] += 1;
      }
    },
    getStatus(status, flag) {
      if (status === "PENDING") {
        return (
          <b-badge variant="warning" class="text-h6">
            Pendiente
          </b-badge>
        );
      }
      if (status === "COMPLETED") {
        return (
          <b-badge variant="success" class="text-h6">
            Terminado
          </b-badge>
        );
      }
      if (status === "TIMEOUT") {
        return (
          <b-badge variant="dark" class="text-h6">
            Tiempo límite
          </b-badge>
        );
      }
      return (
        <b-badge variant="danger" class="text-h6">
          Error
        </b-badge>
      );
    },

    getAttachments(portalItem) {
      let ret = [];
      if (portalItem) {
        ret = portalItem?.worksession_survey_response_attachments;
      }
      return ret;
    },

    getPunctuationLabel(portalItem) {
      let str = ``;
      let punctuationValue = this.getPunctuationValue(
        portalItem.worksession_survey_photos_portal
      );
      if (punctuationValue !== null) {
        return `Punctuation: ${punctuationValue} / 5`;
      }
      return str;
    },
    getPunctuationValue(portalItem) {
      let ret = null;
      if (portalItem) {
        const {
          scoreGPV,
          scoreGPVBy,
          ppScoreGPVBy,
          scoreBrand,
          scoreBrandBy,
          ppScoreBrandBy,
          scoreAdmin,
          scoreAdminBy,
          ppScoreAdminBy
        } = portalItem;
        if (scoreBrand !== null && !isNaN(scoreBrand)) {
          return scoreBrand;
        } else if (scoreAdmin !== null && !isNaN(scoreAdmin)) {
          return scoreAdmin;
        } else if (scoreGPV !== null && !isNaN(scoreGPV)) {
          return scoreGPV;
        }
      }
      return ret;
    },
    commentsPhotosPortal(portalItem) {
      let ret = [];
      if (portalItem && portalItem?.worksession_survey_photos_portal) {
        const {
          commentsAdmin,
          scoreAdminBy,
          isCommentsAdmin,
          ppScoreAdminBy,
          commentsBrand,
          scoreBrandBy,
          isCommentsBrand,
          ppScoreBrandBy,
          commentsGPV,
          scoreGPVBy,
          isCommentsGPV,
          ppScoreGPVBy,
          scoreGPV,
          scoreAdmin,
          scoreBrand
        } = portalItem.worksession_survey_photos_portal;
        ret.push({
          label: "Admin",
          text: commentsAdmin !== null ? commentsAdmin : "",
          rating: this.checkAndChangeScore(scoreAdmin),
          isAccess: false
        });
        ret.push({
          label: "Marca",
          text: commentsBrand !== null ? commentsBrand : "",
          rating: this.checkAndChangeScore(scoreBrand),
          isAccess: false
        });
        ret.push({
          label: "GPV",
          text: commentsGPV !== null ? commentsGPV : "",
          rating: this.checkAndChangeScore(scoreGPV),
          isAccess: false
        });
      }
      return ret;
    },
    checkAndChangeScore(score) {
      if (score !== null && !isNaN(score)) return score;
      return 0;
    },

    async downloadMessageExcel() {
      this.showLoadingInstance();
      let fetch_url =
        "reporting/sales/salespedidoemail/salesemail/downloadmessage";
      let body = {
        email: this.selected.email,
        worksessionSalesParentId: this.selected.worksessionSalesParentId
      };
      try {
        const response = await ApiService.post(fetch_url, body, {
          responseType: "blob"
        });
        download(response, "message_template.xlsx");
        this.closeLoadingInstance();
      } catch (error) {
        this.closeLoadingInstance();
      }
    },

    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.showLoadingInstance();
      this.loading = true;
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "reporting/sales/salespedidoemail/salesEmail";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.total = resp.total;
      this.salesEmail = resp.data;
      this.loading = false;
      this.closeLoadingInstance();
    },

    async onItemShow(item) {
      if (item.status === "ERROR") {
        logError("No hay correo electrónico cuando se produce un error.");
      } else {
        await this.getMessage(item);
        this.showMessageDialog = true;
      }
    },

    async getMessage(item) {
      const { email, worksessionSalesParentId } = item;
      let fetch_url = "reporting/sales/salespedidoemail/salesemail/getmessage";
      this.isLoadingSpinner = true;
      try {
        const resp = await ApiService.post(fetch_url, {
          email,
          worksessionSalesParentId
        });
        this.selected = resp.data;
        this.selected = {
          ...this.selected,
          email,
          worksessionSalesParentId
        };
      } catch (error) {
        console.log(error);
      }
      this.isLoadingSpinner = false;
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    closeMessageDialog() {
      this.showMessageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
      });
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showLoadingInstance() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#sales_emails_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    closeLoadingInstance() {
      this.loadingInstance.close();
    },

    async getPosFotosDetail() {
      let fetch_url = `pos/posfotos/getPosFotosPage/${this.brandId}/${this.posId}/${this.surveyId}`;
      this.isLoadingSpinner = true;
      try {
        const resp = await ApiService.get(fetch_url);
        this.routePos = resp.routePos;
        this.worksessionSurveyResponses = resp.worksessionSurveyResponses;
        this.foundPortals = resp.surveyPhotosPortals;
        // Ensure portalItemIndex is initialized properly
        this.foundPortals?.forEach((_, key) => {
          if (!this.portalItemIndex[key]) {
            this.$set(this.portalItemIndex, key, 0);
          }
        });
        let index = 0;
        for (let i = 0; i < this.worksessionSurveyResponses?.length; i++) {
          const kpi_len = this.worksessionSurveyResponses[i]?.length;
          if (this.kpi_length < kpi_len)
            (this.kpi_length = kpi_len), (index = i);
        }
        this.worksessionSurveyResponses?.[index]?.forEach(el => {
          this.kpis.push(el?.["survey_question"]?.["name"]);
        });
        console.log(
          "----------------   kpi length  --------------",
          this.kpi_length
        );
      } catch (error) {
        console.log(error);
      }
      this.isLoadingSpinner = false;
    },

    init() {
      // this.getDataFromApi();
      this.surveyId = this.$route.params.surveyId;
      this.brandId = this.$route.params.brandId;
      this.posId = this.$route.params.posId;
      this.getPosFotosDetail();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td[col-key="logo"].ve-table-body-td {
  text-align: center !important;
}
.table-body-cell-yellow {
  background-color: #e3e308bf !important;
}
.table-body-cell-green {
  background-color: #12e112 !important;
}
.table-body-cell-red {
  background-color: #df2121 !important;
}
</style>
